<template>
  <layout>
    <div id="cars">
      <b-container>
        <b-row align-h="center">
          <b-col sm="auto">
            <h3 id="title-container">mon profil</h3>
            <div v-if="!loaded" class="ui icon message">
              <span class="notched circle loading icon"></span>
              <div class="content">
                <div>Juste une petite seconde</div>
                <p>Nous récupérons vos données utilisateur pour vous 🙂</p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col sm="auto" class="profile-container menu-profile">
            <button @click="menuMode = 'profile'">Profil</button>
            <button disabled @click="menuMode = 'editPassword'">Modifier le mot de passe</button>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col sm="auto" class="profile-container menu-selected-profile">
            <div v-if="menuMode == 'profile'">
              <p>Nom: {{ user.lastname }}</p>
              <p>Prénom: {{ user.firstname }}</p>
              <p>Adresse mail: {{ user.email }}</p>
              <p>
                Date de naissance:
                {{ new Date(user.birthdate) | filterFormatDate }}
              </p>
              <p v-if="user.phonenumber">Numéro de téléphone: {{ user.phonenumber }}</p>
              <p>Genre: {{ user.gender | translateGender }}</p>
            </div>
            <div v-else-if="menuMode == 'editPassword'">
              <h3>Modifier votre mot de passe</h3>
              <label for="input">Mot de passe actuel :</label>
              <b-input type="password" :minlength="password.minlength" v-model="password.value.now" name="password"
                style="width: 100%" />
              <label for="input">Nouveau mot de passe :</label>
              <b-input type="password" :minlength="password.minlength" v-model="password.value.new" name="newpassword"
                style="width: 100%" />

              <button @click="confirmUpdatePassword" class="ui blue basic button">Modifier le mot de passe</button>
            </div>
            <transition name="bounce" mode="out-in" appear>
              <div key="errorrr" v-if="errorMessage && loaded" class="ui error message">
                <div>Une erreur est survenue</div>
                <p>{{ errorMessage }}</p>
              </div>
              <div key="successs" v-else-if="successMessage && loaded" class="ui success message">
                <div>Opération effectué avec succès</div>
                <p>{{ successMessage }}</p>
              </div>
            </transition>
          </b-col>
        </b-row>
      </b-container>
    </div>

  </layout>
</template>

<script>
import api from '../api'
import auth from '../services/auth'
import { emailRegExp } from '../utils/regex.utils'
import Layout from '../layouts/Layout.vue'

export default {
  components: { Layout },

  name: 'UserProfile',
  data: () => {
    return {
      errorMessage: '',
      successMessage: '',
      loaded: false,
      user: {},
      password: {
        value: {
          now: '',
          new: '',
        },
        minlength: 3,
      },
      email: '',
      menuMode: 'profile',
    }
  },
  methods: {
    confirmUpdatePassword() {
      if (this.password.value.new.length > this.password.minlength) {
        api
          .updatePassword({
            token: auth.retrieveToken(),
            password: this.password.value.now,
            newPassword: this.password.value.new,
          })
          .then((response) => {
            this.errorMessage = ''
            this.successMessage = response.data.message
          })
          .catch((err) => {
            this.successMessage = ''
            this.errorMessage = err.error
          })
      } else {
        this.errorMessage = 'Le mot de passe doit comporter au minimum 3 caractères'
      }
    },
    sendLinkResetEmail() {
      if (this.email && emailRegExp.test(this.email)) {
        api
          .sendLinkToResetEmail({
            newEmail: this.email,
            token: auth.retrieveToken(),
          })
          .then((response) => {
            this.errorMessage = ''
            this.successMessage = response.data.message
          })
          .catch((err) => {
            this.successMessage = ''
            this.errorMessage = err.error
          })
      } else {
        this.errorMessage = "Le format de l'adresse mail est incorrect"
      }
    },
  },
  filters: {
    filterFormatDate: function (date) {
      let resultDate = date.toLocaleString('fr-FR', { timeZone: 'UTC' })
      return resultDate.slice(0, 10)
    },
    translateGender: function (gender) {
      if (gender == undefined) return 'Non renseigné'

      if (gender) return 'Femme'
      else return 'Homme'
    },
  },
  mounted() {
    api
      .readUser()
      .then((response) => {
        this.errorMessage = ''
        this.user = response.data
      })
      .catch((err) => {
        this.errorMessage = err.error
      })
      .finally(() => {
        this.loaded = true
      })
  },
}
</script>

<style>
@media (max-width: 640px) {
  .profile-container {
    margin: 50px;
    background-color: transparent;
    border-radius: 5px;
    margin-top: 20px;
  }
}

.profile-container {
  padding: 0;
  background-color: transparent;
  border-radius: 5px;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
}

.profile-container>* {
  padding: 20px 0 20px 0;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.menu-profile {
  background-color: transparent;
  display: contents;
  row-gap: 20px;
}

.menu-profile>button {
  background-color: transparent;
  border: none;
  padding: 5px;
  margin: 0 10px;
}

.menu-profile>button:hover {
  transform: scale(1.05);
  transition-duration: 500ms;
}

.menu-selected-profile {
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
}

.menu-selected-profile>p {
  padding: 3% 1% 25% 2%;
  color: rgb(53, 53, 53);
  font-size: 20px;
}

.menu-selected-profile>div {
  padding: 30px;
}

.menu-selected-profile>div>input {
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
}

.menu-selected-profile>.button {
  width: 100%;
}

.menu-selected-profile>button {
  border: 0;
  background-color: rgb(250, 250, 250);
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}
</style>

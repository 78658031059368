<template>
  <layout>
    <div class="main-container">
      <b-container fluid>
        <b-row align-h="center">
          <b-col md="auto" class="login-container">
            <div class="register">
              <h1 id="title-container">Ajouter un entretien</h1>
              <p id="under-title">Ajouter un nouvel entretien en remplissant les champs requis ci-dessous</p>

              <transition mode="out-in" name="bounce">
                <div>
                  <div key="success" class="ui success message mini" v-if="successMessage">
                    <div>Opération réalisée avec succès</div>
                    <p>{{ successMessage }}</p>
                  </div>
                  <div key="error" class="ui error message mini" v-if="errorMessage">
                    <div>Une erreur est survenue</div>
                    <p>{{ errorMessage }}</p>
                  </div>
                </div>
              </transition>

              <!-- INPUT -->
              <div class="form-group">
                <div class="input-group input-maintenance-registration">
                  <input v-model="description" class="form-control" title="Description de l'opération" autofocus
                    placeholder="Description..." type="text" pattern="^.{1,30}$" />
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="date" class="form-control" autofocus title="Date lors de l'opération"
                    placeholder="Date de l'opération" type="date" pattern="^.{1,10}$" />
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="mileage" class="form-control" placeholder="Kilométrage" type="number" min="0"
                    title="Kilométrage lors de l'entretien réalisé" />
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="author" class="form-control" placeholder="Responsable" type="text"
                    title="Auteur de l'opération" />
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="reason" class="form-control" placeholder="Cause" type="text"
                    title="Cause de la maintenance" />
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="price" class="form-control" placeholder="Coût de l'opération" type="number" min="1"
                    step="any" title="Prix de la main d'oeuvre, pièces.." />
                </div>

                <div class="ui icon input input-group input-maintenance-registration">
                  <label v-if="invoice" class="ui grey below label">{{ invoice.name }} </label>
                  <input type="file" style="display: none" title="Facture" @change="onFileSelected" placeholder="Image"
                    ref="fileInput" />
                  <button class="fluid ui basic labeled icon button" @click="$refs.fileInput.click()">
                    <i class="image icon"></i>
                    Déposer une facture
                  </button>
                </div>
              </div>

              <!-- BUTTONS ACTIONS -->
              <b-button @click="onClickAddMaintenance" block variant="primary" size="sm" id="button-register">Ajouter
                l'entretien</b-button>
            </div>

            <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </layout>
</template>

<script>
import api from '../api'
import router from '../router'
import Layout from '../layouts/Layout.vue'

export default {
  name: 'AddMaintnance',
  components: { Layout },

  data() {
    return {
      description: '',
      date: '',
      mileage: '',
      author: '',
      reason: '',
      price: '',
      car: {},
      invoice: null,
      errorMessage: '',
      successMessage: '',
    }
  },
  methods: {
    onFileSelected(event) {
      this.invoice = event.target.files[0]
    },
    async onClickAddMaintenance() {
      if (this.description && this.date && this.mileage) {

        // Update invoice first
        let newInvoiceID = null
        if (this.invoice) {
          const { fileName, success, errors, _message } = await api.storeFile(this.invoice)
          if (success) {
            newInvoiceID = fileName
            this.errorMessage = ''
          }
        }

        api
          .createMaintenance({
            description: this.description,
            date: this.date,
            mileage: this.mileage,
            reason: this.reason,
            price: this.price,
            author: this.author,
            invoice: newInvoiceID,
            carId: this.car.id,
          })
          .then((response) => {
            this.errorMessage = ''
            this.$notify({
              type: 'success',
              title: 'Entretien',
              text: `Votre entretien "${response.data.description}" à été créée avec succès !`,
              duration: 5000,
              speed: 1000,
            })
            router.push({ name: 'CarProfile' })
          })
          .catch((e) => {
            this.errorMessage = e.error
            this.successMessage = ''
          })
      } else {
        this.errorMessage = "Les champs suivis d'un astérisque sont obligatoires"
      }
    },
  },
  mounted() {
    this.car = JSON.parse(localStorage.getItem('car_selected'))
  },
}
</script>

<style>
/* login */
.main-container {
  margin: 30px 0 100px 0;
}

.register {
  padding: 10px 10% 10px 10%;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 1px;
}

.form-control {
  font-size: 13px;
}

#button-register {
  margin: 20px 0 20px 0;
}

.input-maintenance-registration {
  margin-bottom: 20px;
}
</style>

import {createRouter, createWebHistory} from 'vue-router'
import auth from './services/auth'

const router = createRouter({
  mode: 'history',

  history: createWebHistory(),
  routes: [
    {
      name: 'Root',
      path: '/',
      beforeEnter(_to, _from, next) {
        if (auth.isAuthentificated()) next({ name: 'Garage' })
        else next({ name: 'Login' })
      },
    },
    {
      name: 'Login',
      path: '/login',
      component: require('./pages/Login.vue').default,
    },
    {
      name: 'Registration',
      path: '/registration',
      component: require('./pages/Registration.vue').default,
    },
    {
      name: 'ForgottenPassword',
      path: '/forgottenpassword',
      component: require('./pages/ForgottenPassword.vue').default,
    },
    {
      name: 'Contact',
      path: '/contact',
      component: require('./pages/Contact.vue').default,
    },
    {
      name: 'About',
      path: '/about',
      component: require('./pages/About.vue').default,
    },
    {
      name: 'ResetPassword',
      path: '/users/me/resetpassword/:token',
      component: require('./pages/ResetPassword.vue').default,
    },
    {
      name: 'ConfirmAccount',
      path: '/users/confirmation/:token',
      component: require('./pages/ConfirmAccount.vue').default,
    },
    {
      name: 'AddCar',
      path: '/cars/new',
      component: require('./pages/AddCar.vue').default 
      
    },
    {
      name: 'CarProfile',
      path: '/cars/:carid',
      component: require('./pages/CarProfile.vue').default,
    },
    {
      name: 'UserProfile',
      path: '/user/me',
      component: require('./pages/UserProfile.vue').default,
    },
    {
      name: 'EditCar',
      path: '/cars/:carid/edit',
      component: require('./pages/EditCar.vue').default,
    },
    {
      name: 'AddMaintenance',
      path: '/cars/:carid/maintenances/new/',
      component: require('./pages/AddMaintenance.vue').default,
    },
    {
      name: 'EditMaintenance',
      path: '/cars/:carid/maintenances/:maintenanceid/edit',
      component: require('./pages/EditMaintenance.vue').default,
    },

    {
      name: 'Garage',
      path: '/cars',
      component: require('./pages/Garage.vue').default,

    },
    /* URL not valid */
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'Root' },
    },
  ],
})

export default router

<template>
  <div class="main-container">
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col md="auto" id="column-logo">
          <transition name="slide-fade" appear>
            <img alt="Mon Suivi Auto" id="logo" title="Mon Suivi Auto" :src="`${publicPath}/logo.png`" />
          </transition>
        </b-col>

        <b-col xl="5" sm="9" xs="8" md="auto" id="login-container">
          <div id="login">
            <h2 id="title-login">Mon Suivi Auto</h2>
            <transition mode="out-in" name="bounce">
              <div>
                <div key="load" v-if="!errorMsg && showLoading" class="ui tiny icon message">
                  <span class="spinner loading icon"></span>
                  <div class="content">
                    <div>Démarrage</div>
                    <p>Synchronisation de vos données 🤖</p>
                  </div>
                </div>
                <div key="error" class="ui error message mini" v-if="errorMsg">
                  <div>Une erreur est survenue</div>
                  <p>{{ errorMsg }}</p>
                </div>
              </div>
            </transition>
            <!-- LOGIN -->
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span class="fa fa-user"></span>
                  </span>
                </div>
                <b-form-input name="email" size="md" v-model="email" placeholder="Adresse mail"
                  type="email"></b-form-input>
              </div>
            </div>

            <!-- PASSWORD -->
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span class="fa fa-lock"></span>
                  </span>
                </div>
                <b-form-input name="password" v-model="password" @keydown.enter="login" size="md" type="password"
                  placeholder="Mot de passe"></b-form-input>
              </div>
            </div>
            <!-- BUTTON CONNECT -->
            <b-button @click.prevent="login" block variant="primary" size="md">Connexion</b-button>
            <GoogleLogin :callback="handleGoogleLogin" />
            <div class="forgot-password">
              <router-link to="/forgottenpassword"> Mot de passe oublié ? </router-link>
            </div>
          </div>
          <div id="registration">
            Pas de compte ?
            <router-link to="/registration" id="registration-link"> Inscrivez vous </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from '../api'
import router from '../router'
import { decodeCredential } from 'vue3-google-login'

export default {
  name: 'Login',
  data: () => {
    return {
      email: '',
      password: '',
      showLoading: false,
      errorMsg: '',
      publicPath: window.location.origin,
    }
  },
  mounted() {
    console.log('Environment: ', process.env.NODE_ENV)
  },
  methods: {
    loading() {
      this.showLoading = !this.showLoading
    },
    async handleGoogleLogin(response) {
      console.log("handleGoogleLogin", response);
      const userData = decodeCredential(response.credential);
      console.log("userData", userData);
      this.loading()
      api.login({
        email: userData.email,
        google_objectid: userData.sub,
        google_firstname: userData.given_name,
        google_lastname: userData.family_name,
      })
        .then(() => {
          router.push({ name: 'Garage' })
        })
        .catch((error) => {
          this.errorMsg = error
          this.loading()
        })
    },
    async login() {
      if (!this.showLoading) {
        this.errorMsg = null
        this.loading()
        api.login({
          email: this.email,
          password: this.password,
        })
          .then(() => {
            router.push({ name: 'Garage' })
          })
          .catch((error) => {
            this.errorMsg = error
            this.loading()
          })
      }
    },
  },
}
</script>

<style>
/* responsive */

@media only screen and (max-width: 991px) {

  #logo,
  #column-logo {
    background-image: none;
    display: none;
  }
}



.input-group-prepend,
.input-group-append {
  display: flex;
  margin: 0;
}

#login,
#registration {
  padding: 30px;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 5px;
}

#logo,
#column-logo {
  width: 400px;
  float: right;
  justify-content: right;
}

#title-login {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  padding: 5px 40px 30px 40px;
}

#or {
  color: grey;
  padding: 25px 0 10px 0;
  text-align: center;
}

.forgot-password {
  margin-bottom: 1.2em;
  display: flex;
  text-align: center;
  justify-content: center;
}


.forgot-password a {
  font-size: 0.8em;
  color: #191091;
}

#registration {
  font-size: 16px;
  text-align: center;
  padding: 15px;
  margin-top: 2%;
}

#registration-link {
  color: #281d9c;
}

#login>* {
  margin: 10px 0 10px 0;
}
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Layout from './layouts/Layout.vue';
import auth from './services/auth'

import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

/* import from public folder, custom css */


export default {
  name: 'App',
  components: { Layout },
  data: () => {
    return {
      auth: false,
    }
  },
  compatConfig: { MODE: 3 },
  updated() {
    this.auth = auth.isAuthentificated()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.auth = auth.isAuthentificated()
    })
  },
}
</script>

<style>
@import '/style.css';
@import '/form.css';
</style>

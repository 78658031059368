<template>
  <layout>
    <main-bar>
      <img v-if="car.pictureURL == '/default_car.png'" class="thumb-car img-car-profile" :src="car.pictureURL" />
      <div v-else class="thumb-car img-car-profile" :style="{
        '--bkgImage': 'url(' + this.car.pictureURL + ')',
      }" />
      <div class="car-informations-header">
        <div class="car-header-main-infos d-flex align-items-center">
          <h3 class="w-100">{{ this.car.registration }}</h3>
          <span v-if="car.registrationDate" class="w-100 ">{{ new Date(this.car.registrationDate).toLocaleDateString()
          }}</span>
          <span class="w-100" v-else>Immatriculé il fut un temps...</span>
        </div>
        <router-link :to="`/cars/${car.id}/edit`">
          <i class="edit icon big"></i>
        </router-link>

        <div class="car-header-secondary-infos">
          <span>{{ this.car.brand }}</span>
          <span>{{ this.car.model }} <span>{{ this.car.modelVersion }}</span></span>

          <span>{{ this.car.fuel }}</span>

          <span v-if="car.horses">{{ this.car.horses }} cv</span>
          <span>{{ this.maintenances.length }} maintenance(s) effectuée(s)</span>
        </div>
      </div>
    </main-bar>
    <div v-if="!loaded" class="ui icon message">
      <span class="notched circle loading icon"></span>
      <div class="content">
        <div>Juste une petite seconde</div>
        <p>Nous récupérons vos données pour vous 😉</p>
      </div>
    </div>
    <div class="m-4">
      <ul class="list-group">
        <div v-for="maintenance in maintenances" :key="maintenance.id">
          <li class="list-group-item">
            <div @click="getInvoice(maintenance.invoice)">
              <div class="list-group-div-item d-flex w-100 justify-content-start align-items-center">
                <i
                  :class="` left big  ${maintenance.invoice != 'null' ? 'pdf file outline invoice-icon pointer' : ' unlink '}  icon`"></i>
                <div class="w-50">
                  <h5 class="mb-1">{{ maintenance.description }}</h5>
                  <p class="mb-1" v-if="maintenance.price">Prix : {{ maintenance.price }} €</p>
                </div>
                <div class="w-50 text-center">
                  <p class="mb-1" v-if="maintenance.reason">{{ maintenance.reason }}</p>
                  <small v-if="maintenance.mileage && maintenance.author">Réalisée le {{ new
                    Date(maintenance.date).toLocaleDateString() }} à
                    {{ maintenance.mileage }} KM par {{ maintenance.author }}</small>
                </div>
                <div class="flex-shrink-1">
                  <router-link :to="`/cars/${car.id}/maintenances/${maintenance.id}/edit`">
                    <i class="edit outline icon big"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
        </div>
        <li class="list-group-item">
          <div class="d-flex w-100 justify-content-start">
            <div class="vertical-center">
              <router-link :to="createMaintenanceUrl">
                <span class="fa fa-plus add-maintenance" aria-hidden="true"></span>
                <button class="btn w-100">Ajouter un nouvel entretien</button>
              </router-link>
            </div>
          </div>
        </li>
        <div>
          <b-button v-if="maintenances.length > 0" class="btn m-0" size="sm" @click="exportCarProfile">Exporter
            les entretiens</b-button>
        </div>
      </ul>
    </div>

  </layout>
</template>

<script>
import TransitionExpand from '../components/TransitionExpand'
import api from '../api'
import Layout from '../layouts/Layout.vue'
import MainBar from '../components/MainBar.vue'

export default {
  components: { Layout },

  name: 'CarProfile',
  data: () => {
    return {
      errorMessage: '',
      car: {},
      carId: null,
      editUrl: '',
      createMaintenanceUrl: '',
      loaded: false,
      showDetails: false,
      maintenanceIdDetails: '',
      editMaintenance: '',
      pictureURL: '',
      maintenances: [],
    }
  },
  components: {
    'transition-expand': TransitionExpand, Layout,
    MainBar
  },
  methods: {
    toggleShowDetails(maintenanceId) {
      if (this.maintenanceIdDetails == maintenanceId) {
        this.showDetails = !this.showDetails
      } else {
        this.showDetails = true
      }
      this.maintenanceIdDetails = maintenanceId
    },
    /* get invoice */
    getInvoice(invoice) {
      if (!invoice || invoice == "null") return;

      api
        .getFile(invoice)
        .then((response) => {
          const blob = new Blob([response], {
            type: response.type,
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Facture-${this.car.registration}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((e) => {
          this.errorMessage = e.error
          this.successMessage = ''
        })
    },
    /* export pdf */
    exportCarProfile() {
      api
        .exportMaintenances({
          carId: this.$route.params.carid,
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Historique-${this.car.registration}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((e) => {
          this.errorMessage = e.error
          this.successMessage = ''
        })
    },
  },
  filters: {
    filterFormatDate: function (date) {
      let resultDate = date.toLocaleString('fr-FR', { timeZone: 'UTC' })
      return resultDate.slice(0, 10)
    },
  },
  // computed: {
  //   ...mapGetters('cars', {
  //     getCarById: 'getCarById',
  //     cars: 'cars',
  //   }),
  //   ...mapGetters('maintenances', {
  //     maintenances: 'maintenances',
  //   }),
  // },
  created() {

    this.car = JSON.parse(localStorage.getItem('car_selected'))
    if (!this.car.pictureURL) {
      this.car.pictureURL = "/default_car.png"
    }
    this.editUrl = '/cars/' + this.car.id + '/edit'
    this.createMaintenanceUrl = '/cars/' + this.car.id + '/maintenances/new'
    api.loadMaintenances(this.car.id)
      .then((response) => {
        this.errorMessage = ''
        console.log(response)
        this.maintenances = response.data
      })
      .catch((err) => {
        this.errorMessage = err.error
      })
      .finally(() => {
        this.loaded = true
      })
  },
}
</script>

<style>
.add-maintenance {
  display: block;
  text-align: center;
  font-size: 20px;
}

.list-group-item {
  min-height: 75px;
}

.img-car-profile {
  background: var(--bkgImage) center;
  float: left;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
  background-size: cover;
  filter: drop-shadow(0px 0px 1px #272727);
  margin-right: 20px;
}

.maintenance-details {
  padding: 0px;
  margin-top: 10px;
  font-family: 'Roboto';
  font-size: 13px;
  text-align: left;
}


.car-informations-header {
  display: flex;
  align-items: center;
  height: 100%;
}

.car-header-secondary-infos {
  display: flex;
  gap: 30px
}

.car-header-main-infos {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  border-right: var(--secondary-text-color) 1px solid;
  max-width: fit-content;
  padding-right: 0px;
  margin-right: 20px;
  flex-wrap: wrap;
}


.list-group-div-item {
  gap: 3rem
}


.w-100 i {
  padding: 5px;
  float: right;
}

@media (max-width: 640px) {

  .car-informations-header,
  .car-header-secondary-infos {
    display: block;
  }

  .car-header-main-infos {
    border-right: none;
    border-left: var(--secondary-text-color) 1px solid;
    padding-left: 10px;
    padding-right: 0px;
    margin-right: 0px;
  }

  a {
    margin-top: 10px !important;
    width: 100%;
    display: block;
    margin: auto;
  }

  .car-header-secondary-infos {
    margin-top: 10px;

    span {
      margin-right: 10px;
    }
  }

  .main-bar {
    display: block;
    height: fit-content;
  }

  .list-group-div-item {
    gap: .5rem;
    font-size: 12px;

    h5 {
      font-size: 14px;
    }

    small {
      font-size: smaller;
    }
  }

  .btn-export {
    width: 100%;
  }
}

.invoice-icon {
  display: float;
  float: left;
  color: rgb(228, 20, 20);
}

.btn-export {
  float: right;
}

.red {
  background-color: #c51010;
}

.white {
  color: #fff;
}

.pointer {
  cursor: pointer;
}
</style>

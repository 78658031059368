<template>
  <div class="main-container">
    <b-container fluid>
      <b-row align-h="center">
        <b-col md="auto" class="login-container">
          <div class="register">
            <h1 id="title-container">Mon Suivi Auto</h1>
            <p id="under-title">Inscrivez-vous pour gérer et consulter vos entretiens automobiles.</p>
            <transition mode="out-in" name="bounce">
              <div>
                <div key="success" class="ui success message mini" v-if="successMessage">
                  <div>Inscription prise en compte avec succès !</div>
                  <p>{{ successMessage }}</p>
                </div>
                <div key="error" class="ui error message mini" v-if="errorMessage">
                  <div>Une erreur est survenue</div>
                  <p>{{ errorMessage }}</p>
                </div>
              </div>
            </transition>
            <!-- INPUT -->
            <div class="form-group">
              <div class="input-group input-registration">
                <input v-model="email" class="form-control" autofocus placeholder="Adresse mail" type="email"
                  pattern="^.{1,100}$" />
                <span class="required-field">*</span>
              </div>
              <div class="input-group input-registration">
                <div class="input-icons">
                  <input v-model="password.value" class="form-control" placeholder="Mot de passe"
                    :type="password.fieldType" pattern="^.{5,100}$" title="Mot de passe (5 caractères minimum requis)" />
                  <span @click="switchPasswordVisibility" title="Afficher le mot de passe" class="fas fa-eye"></span>
                  <span class="required-field">*</span>
                </div>
              </div>
              <div class="input-group input-registration">
                <input v-model="firstname" class="form-control" placeholder="Prénom" type="text" title="Prénom"
                  pattern="^[A-Za-z -]+$" />
                <span class="required-field">*</span>
              </div>
              <div class="input-group input-registration">
                <input v-model="lastname" class="form-control" placeholder="Nom" type="text" title="Nom"
                  pattern="^[A-Za-z -]+$" />
                <span class="required-field">*</span>
              </div>
            </div>

            <!-- BUTTON REGISTER -->
            <b-button @click="confirmRegistration" block variant="primary" size="sm" id="button-register">Confirmer
              l'inscription</b-button>
          </div>
          <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from '../api'
import { emailRegExp, birthdateRegExp } from '../utils/regex.utils'

export default {
  name: 'Registration',

  data() {
    return {
      email: '',
      password: {
        value: '',
        fieldType: 'password',
        minlength: 3,
      },
      firstname: '',
      lastname: '',
      phonenumber: '',
      errorMessage: '',
      successMessage: '',
    }
  },
  methods: {
    switchPasswordVisibility() {
      this.password.fieldType = this.password.fieldType === 'password' ? 'text' : 'password'
    },
    confirmRegistration() {
      if (
        this.isEmail(this.email) &&
        this.password.value.length > this.password.minlength &&
        this.firstname &&
        this.lastname
      ) {
        api
          .registrationUser({
            email: this.email,
            password: this.password.value,
            firstname: this.firstname,
            lastname: this.lastname
          })
          .then((response) => {
            this.successMessage = response.data.message
            this.errorMessage = ''
          })
          .catch((e) => {
            this.errorMessage = e.error
            this.successMessage = ''
          })
      } else {
        this.errorMessage = "Les champs suivis d'un astérisque sont obligatoires"
      }
    },
    // validate by type and value
    validate: function (type, value) {
      if (type === 'email') {
        this.email.valid = this.isEmail(value) ? true : false
      }
    },
    // check for valid email adress
    isEmail: function (value) {
      return emailRegExp.test(value)
    },
  },
}
</script>

<style>
/* login */
.main-container {
  margin: 30px 0 100px 0;
}

.register {
  padding: 10px 10% 10px 10%;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 1px;
}

.form-control {
  font-size: 13px;
}

#button-register {
  margin: 20px 0 20px 0;
}

#link-go-back {
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
  font-size: 12px;
  padding: 10px 0 10px 0;
  justify-content: center;
  margin-top: 0px;
  display: flex;
  background-color: rgb(235, 235, 235);
}

.input-registration {
  margin-bottom: 20px;
}

.fa-eye {
  padding: 8px;
}

.input-icons:i {
  vertical-align: middle;

  height: 100%;
}

.input-icons {
  align-items: center;
  display: flex;
  width: 100%;
}

.required-field {
  color: red;
}
</style>

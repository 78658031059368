<template>
  <header>
    <b-navbar id="navbar" toggleable="sm" type="dark">

      <b-nav-text v-if="loggedIn" class="text-right text-white">
        Bonjour, <strong>{{ `${this.user?.firstname}` }}</strong>
      </b-nav-text>
    </b-navbar>
  </header>
</template>

<script>
import auth from '../services/auth'
import api from '../api'

export default {
  name: 'AppHeader',

  data: () => {
    return {
      loggedIn: auth.isAuthentificated(),
      user: null,
    }
  },
  created() {
    const user = auth.getUser()
    if (!user) {
      api.readUser()
        .then((response) => {
          this.errorMessage = ''
          auth.storeUser(response.data)
          this.user = response.data
        })
        .catch((err) => {
          this.errorMsg = err.error
        })
      return
    }

    this.user = user
  },

}
</script>

<style>
/* header --- nav bar */
#navbar {
  height: 100%;
  background-color: var(--main-bg-color);
  min-height: 60px;
  font-size: 16px;
  position: sticky;
  top: 0;
}

.img {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
}

.nav-link,
.dropdown-toggle,
.navbar-expand-sm,
.navbar-nav {
  padding: 0px;
  vertical-align: middle;
}

.text-right {
  width: 100%;
  text-align: right;
  margin-right: 80px;
  list-style: none;
}

@media screen and (max-width: 991px) {
  .text-right {
    margin-right: 10px;
  }
}
</style>

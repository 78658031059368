<template>
  <div id="fp-container">
    <b-container fluid>
      <b-row align-h="center">
        <b-col md="auto">
          <div class="fp-container">
            <span class="fas fa-user-check"></span>
            <h3 id="title-container">Activation de votre compte</h3>
            <p id="under-title">Vous avez cliqué sur le lien de confirmation qu'on vous à envoyé</p>

            <transition name="bounce" mode="out-in">
              <div key="error" class="ui error message large" v-if="errorMessage">
                <div>Une erreur est survenue</div>
                <p>{{ errorMessage }}</p>
              </div>
              <div key="success" class="ui success message large" v-else-if="successMessage">
                <div>Succès</div>
                <p>{{ successMessage }}</p>
              </div>
            </transition>
          </div>
          <router-link id="fp-back-to-home" to="/">Se connecter</router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from '../api'
const Cryptr = require('cryptr')
const cryptr = new Cryptr('secret_token_email_access')

export default {
  data: () => {
    return {
      errorMessage: '',
      successMessage: '',
      password: {
        value: '',
        minlength: 5,
      },
      tokenActivateAccount: '',
    }
  },
  methods: {
    confirmRegistrationAccount() {
      api
        .confirmAccount(this.tokenActivateAccount)
        .then((response) => {
          this.errorMessage = ''
          this.successMessage = response.data.message
        })
        .catch((err) => {
          this.successMessage = ''
          this.errorMessage = err.error
        })
    },
  },
  name: 'ConfirmAccount',
  mounted() {
    this.tokenActivateAccount = this.$route.params.token
    this.confirmRegistrationAccount()
  },
}
</script>

<style>
/* container */
#fp-container {
  margin: 30px 0px 110px 0;
  display: flex;
}

.fp-container {
  padding: 18% 15% 18% 15%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
}

.fp-input-email {
  font-size: 12px;
  margin-top: 70px;
}

#fp-back-to-home {
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
  font-size: 12px;
  padding: 10px 0 10px 0;
  justify-content: center;
  margin-top: 0px;
  display: flex;
  background-color: rgb(235, 235, 235);
}
</style>

//import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSidebarMenu from 'vue-sidebar-menu'
import Layout from './layouts/Layout.vue'
import vue3GoogleLogin from 'vue3-google-login'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
if (!clientId) {
  throw new Error('Please define the VUE_APP_GOOGLE_CLIENT_ID environment variable')
}

createApp(App)
.use(BootstrapVue3)
.use(VueSidebarMenu)
.use(vue3GoogleLogin, {clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID})
.use(router)
.component('Layout', Layout)
.mount('#app');


<template>
  <!-- Footer -->
  <div class="footer text-center d-flex justify-content-between">
    <h6 class="footer-items font-weight-bold">Mon Suivi Auto</h6>
    <h6 class="footer-items font-weight-bold">{{ appVersion }}</h6>
    <h6 class="footer-items font-weight-bold">© {{ currentYear }}</h6>
  </div>

  <!-- Footer -->
</template>

<script>
import pjson from '../../package.json'
export default {
  name: 'AppFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      footerItems: [
        {
          id: '1',
          label: 'à propos',
          href: '/about',
        },
        /*{
          id: '2',
          label: 'conditions',
          href: '/conditions',
        },*/
        {
          id: '3',
          label: 'Contact',
          href: '/contact',
        },
      ],
      appVersion: pjson.version,
    }
  },
}
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer-items {
  color: var(--grey);
}

.footer-item h6 {
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-size: 12px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
}

/* Hover => Underline */
.footer-item h5:hover {
  text-decoration: underline;
}

.footer {
  border-top: 1px solid var(--secondary-text-color);
  position: relative;
  bottom: 0px;
  margin: 50px 10px 20px 10px;
  padding-top: 30px;
}
</style>

<template>
  <div id="fp-container">
    <b-container fluid>
      <b-row align-h="center">
        <b-col md="auto">
          <div class="fp-container">
            <span class="icon-key fas fa-key fa-4x"></span>
            <h3 id="title-container">
              Vous avez demander à reinitialiser votre mot de passe
            </h3>
            <p id="under-title">
              Entrez votre nouveau mot de passe ci-dessous
            </p>

            <transition name="bounce" mode="out-in">
              <div key="error" class="ui error message mini" v-if="errorMessage">
                <div>Une erreur est survenue</div>
                <p>{{ errorMessage }}</p>
              </div>
              <div key="success" class="ui success message mini" v-else-if="successMessage">
                <div>Succès</div>
                <p>{{ successMessage }}</p>
              </div>
            </transition>

            <div class="form-group">
              <div class="input-group">
                <input v-model="password.value" class="form-control fp-input-password" placeholder="Nouveau mot de passe"
                  type="password" />
              </div>
            </div>

            <!-- BUTTON SEND LINK -->
            <b-button @click="confirmResetPassword" block variant="primary" size="sm" id="button-send-link">Enregistrer
              mon nouveau mot de passe</b-button>
          </div>
          <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from "../api";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("secret_token_email_access");

export default {
  data: () => {
    return {
      errorMessage: "",
      successMessage: "",
      password: {
        value: "",
        minlength: 5,
      },
      decryptedToken: "",
    };
  },
  methods: {
    confirmResetPassword() {
      if (
        this.password.value &&
        this.password.value.length > this.password.minlength
      ) {
        api
          .resetPassword({
            token: this.decryptedToken,
            password: this.password.value,
          })
          .then((response) => {
            this.errorMessage = "";
            this.successMessage = response.data.message;
          })
          .catch((err) => {
            this.successMessage = "";
            this.errorMessage = err.error;
          });
      } else {
        this.errorMessage =
          "Le mot de passe doit comporter au minimum 5 caractères";
      }
    },
  },
  name: "ResetPassword",
  mounted() {
    this.decryptedToken = cryptr.decrypt(this.$route.params.token);
  },
};
</script>

<style>
/* login */
#fp-container {
  margin: 30px 0px 110px 0;
  display: flex;
}

.icon-key {
  justify-content: center;
  display: flex;
}

.fp-container {
  padding: 18% 15% 18% 15%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
}

.fp-input-email {
  font-size: 12px;
  margin-top: 70px;
}

#fp-back-to-home {
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
  font-size: 12px;
  padding: 10px 0 10px 0;
  justify-content: center;
  margin-top: 0px;
  display: flex;
  background-color: rgb(235, 235, 235);
}
</style>

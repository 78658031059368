<template>
  <layout>
    <div class="main-container">
      <b-container fluid>
        <b-row align-h="center">
          <b-col md="auto" class="login-container">
            <div class="register">
              <h1 id="title-container">Éditer la fiche véhicule</h1>
              <p id="under-title">Remplissez uniquement les champs que vous voulez remplacez</p>

              <transition mode="out-in" name="bounce">
                <div key="error" class="ui error message mini" v-if="errorMessage">
                  <div>Une erreur est survenue</div>
                  <p>{{ errorMessage }}</p>
                </div>
              </transition>

              <!-- INPUT -->
              <div class="form-group">
                <div class="ui icon input input-group input-car-registration">
                  <label v-if="picture" class="ui grey below label">{{ picture.name }} </label>
                  <input type="file" style="display: none" title="Image du véhicule" @change="onFileSelected"
                    placeholder="Image" ref="fileInput" />
                  <button style="display: inline-block" class="fluid ui basic labeled icon button"
                    @click="$refs.fileInput.click()">
                    <i class="image icon"></i>
                    Déposer une image
                  </button>
                </div>

                <div class="input-group input-car-registration">
                  <input v-model="car.registration" class="form-control" autofocus placeholder="AA-123-BB" type="text"
                    pattern="^.{1,10}$" />
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="car.registrationDate" class="form-control" autofocus
                    placeholder="Date d'immatriculation" type="date" pattern="^.{1,10}$" />
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="car.brand" class="form-control" placeholder="Marque" type="text" pattern="^.{5,100}$"
                    title="Marque du véhicule (exemple: Renault)" :disabled="plateLoading" list="datalistBrands" />
                  <datalist id="datalistBrands">
                    <option v-for="b in Object.keys(brands)" :key="b" :value="b"></option>
                  </datalist>
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="car.model" class="form-control" placeholder="Modèle" type="text"
                    title="Modèle (exemple: Clio 4)" />
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="car.modelVersion" class="form-control" placeholder="Version du modèle"
                    pattern="^.{5,100}$" type="text" title="Version du modèle (exemple: 1.5 dCi)" />
                  <datalist id="datalistModels">
                    <option v-for="m in brands[car.brand]" :key="m" :value="m"></option>
                  </datalist>
                </div>
                <div class="input-group input-car-registration">
                  <select class="form-select form-control" aria-label="Motorisation" title="Motorisation"
                    v-model="car.fuel">
                    <option v-for="f in fuels" :key="f" :value="f">{{ f }}</option>
                  </select>
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="car.horses" class="form-control" title="Puissance en chevaux (exemple 90)"
                    placeholder="Puissance" type="number" min="5" pattern="^.{5,100}$" />
                </div>
              </div>

              <!-- BUTTONS ACTIONS -->
              <b-button @click="onClickEditVehicle" block variant="primary" size="sm" @disabled="isLoading">Enregistrer
                les
                modifications</b-button>
              <b-button @click="onClickDeleteVehicle" block variant="danger" size="sm" id="button-delete">Supprimer le
                véhicule</b-button>
            </div>

            <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
          </b-col>
        </b-row>
      </b-container>
    </div>

  </layout>
</template>

<script>
import api from '../api'
import router from '../router'
import Layout from '../layouts/Layout.vue'
import { brandsWithModels } from '../utils/const.utils'

export default {
  name: 'EditCar',
  components: { Layout },

  data() {
    return {
      car: {},
      carProfile: `/cars/${this.$route.params.carid}`,
      picture: null,
      errorMessage: '',
      isLoading: false,
      fuels: ['Diesel', 'Essence', 'Electrique', 'GPL', 'Hybride', 'Hybride rechargeable', 'Ethanol'],
      brands: brandsWithModels

    }
  },
  methods: {
    onFileSelected(event) {
      this.picture = event.target.files[0]
    },
    async onClickEditVehicle() {
      this.isLoading = true
      // Update picture first
      let newPictureId = null
      try {
        if (this.picture) {
          const { fileName, success, errors, _message } = await api.storeFile(this.picture)

          if (success) {
            newPictureId = fileName
            this.errorMessage = ''
          } else {
            this.errorMessage = errors[0]
            return;
          }
        }
      } catch (e) {
        this.errorMessage = e.error
        return;
      }

      api
        .editCar({
          registration: this.car.registration,
          registrationDate: this.car.registrationDate,
          brand: this.car.brand,
          model: this.car.model,
          modelVersion: this.car.modelVersion,
          fuel: this.car.fuel,
          horses: this.car.horses,
          carImage: newPictureId,
          carId: this.car.id,
        })
        .then(() => {
          router.push({ name: 'Garage' })
          this.errorMessage = ''
        })
        .catch((e) => {
          this.errorMessage = e.error
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onClickDeleteVehicle() {
      this.isLoading = true
      api
        .deleteCar(this.car.id)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Véhicule',
            text: 'Votre véhicule à bien été supprimé',
            duration: 5000,
            speed: 1000,
          })
          router.push({ name: 'Garage' })
        })
        .catch((e) => {
          this.errorMessage = e.error
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  created() {
    this.car = JSON.parse(localStorage.getItem('car_selected'))
  },
}
</script>

<style>
/* login */
.main-container {
  margin: 30px 0 100px 0;
}

.register {
  padding: 10px 10% 10px 10%;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 1px;
}

.form-control {
  font-size: 13px;
}

#button-delete {
  margin: 20px 0 20px 0;
}

.input-car-registration {
  margin-bottom: 20px;
}
</style>

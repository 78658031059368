<template>
    <div class="main-bar">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'MainBar',
}
</script>

<style>
.main-bar {
    height: 90px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px;
    margin-bottom: 39px;
    border-bottom: 1px solid var(--secondary-text-color);
}
</style>
<template>
  <div id="fp-container">
    <b-container fluid>
      <b-row align-h="center">
        <b-col md="auto">
          <div class="fp-container">
            <span class="icon-key fas fa-key fa-4x"></span>
            <h3 id="title-container">Vous avez oublié votre mot de passe ?</h3>
            <p id="under-title">
              Entrez votre adresse email et nous vous enverrons un lien pour réinitialiser votre mot de passe
            </p>

            <transition name="bounce">
              <div class="ui error message mini" v-if="errorMessage">
                <div>Une erreur est survenue</div>
                <p>{{ errorMessage }}</p>
              </div>
              <div class="ui success message mini" v-else-if="successMessage">
                <div>Succès</div>
                <p>{{ successMessage }}</p>
              </div>
            </transition>

            <div class="form-group">
              <div class="input-group">
                <input v-model="email" class="form-control fp-input-email" placeholder="Adresse mail" type="email" />
              </div>
            </div>

            <!-- BUTTON SEND LINK -->
            <b-button @click="sendLinkToResetPassword" block variant="primary" size="sm" class="button-send-link">Envoyer
              la
              demande de réinitialisation</b-button>
          </div>
          <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from '../api'
import { emailRegExp } from '../utils/regex.utils'

export default {
  data: () => {
    return {
      errorMessage: '',
      successMessage: '',
      email: new String(''),
    }
  },
  methods: {
    sendLinkToResetPassword() {
      if (emailRegExp.test(this.email)) {
        api
          .sendUrlToResetPassword(this.email)
          .then((response) => {
            this.errorMessage = ''
            this.successMessage = response.data.message
          })
          .catch((err) => {
            this.successMessage = ''
            this.errorMessage = err.error
          })
      } else {
        this.errorMessage = "Format de l'email non valide"
      }
    },
  },
  name: 'ForgottenPassword',
}
</script>

<style>
/* login */
#fp-container {
  margin: 30px 0px 110px 0;
  display: flex;
}

.icon-key {
  justify-content: center;
  display: flex;
}

.fp-container {
  padding: 18% 15% 18% 15%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
}

.fp-input-email {
  font-size: 12px;
  margin-top: 70px;
}

#fp-back-to-home {
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 2px;
  font-size: 12px;
  padding: 10px 0 10px 0;
  justify-content: center;
  margin-top: 0px;
  display: flex;
  background-color: rgb(235, 235, 235);
}
</style>

<template>
  <div id="main-container">
    <b-container fluid="sm">
      <b-row align-h="center">
        <b-col md="9" id="cp-container">
          <h1 id="title-container" class="about-h1">À propos</h1>
          <div class="about-paragraph">
            <h2 class="about-h2">Qui suis-je ?</h2>
            <p class="about-content">
              Mon Suivi Auto à été crée par

              <span class="fab fa-linkedin"></span>
              <a href="https://www.linkedin.com/in/samy-mokhtari/"> Samy Mokhtari </a>
            </p>
          </div>

          <div class="about-paragraph">
            <h2 class="about-h2">Mon Suivi Auto</h2>
            <p class="about-content">
              Vous avez des besoins en terme de suivi de votre véhicule ? Mon Suivi Auto vous propose de lister chaque
              entretien que vous avez effectué de manière simple et rapide. En vous créant un compte vous pourrez
              répertorier chacun de vos véhicules, et lui ajouter autant d'entretien que nécessaire en y associant les
              factures et les caractéristiques de ces derniers.
            </p>
            <p class="about-content">
              Ainsi, il sera également possible d'exporter une fiche récapitulative de vos entretiens concernant un
              véhicule au format PDF.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'About',
}
</script>

<style>
/* responsive */
@media only screen and (max-width: 991px) {

  #logo,
  #column-logo {
    background-image: none;
    display: none;
  }
}

/* login */
#main-container {
  margin: 3% 0 8% 0;
}

#cp-container {
  padding: 30px;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 5px;
}

#link-icones8,
small {
  font-size: 14px;
  color: rgb(161, 161, 161);
  justify-content: center;
  display: flex;
}

.about-h1 {
  font-size: 88px;
}

.about-h2 {
  font-size: 25px;
  font-family: 'Overpass', sans-serif;
}

.about-content {
  font-size: 15px;
  padding: 0 5px 0 5px;
  font-family: 'Hind', sans-serif;

  text-align: justify;
}

.about-paragraph {
  margin: 50px 5px 50px 5px;
}
</style>

<template>
  <layout>
    <div class="main-container">
      <b-container fluid>
        <b-row align-h="center">
          <b-col md="auto" class="login-container">
            <div class="register">
              <h1 id="title-container">Éditer un entretien</h1>
              <p id="under-title">Remplissez uniquement les champs que vous voulez remplacez</p>

              <transition mode="out-in" name="bounce">
                <div key="error" class="ui error message mini" v-if="errorMessage">
                  <div>Une erreur est survenue</div>
                  <p>{{ errorMessage }}</p>
                </div>
              </transition>

              <!-- INPUT -->
              <div class="form-group">
                <div class="input-group input-maintenance-registration">
                  <input v-model="maintenance.description" class="form-control" title="Description de l'opération"
                    autofocus placeholder="Description..." type="text" pattern="^.{1,30}$" />
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="maintenance.date" class="form-control" autofocus title="Date lors de l'opération"
                    placeholder="Date de l'opération" type="date" pattern="^.{1,10}$" />
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="maintenance.mileage" class="form-control" placeholder="Kilométrage" type="number"
                    title="Kilométrage lors de l'entretien réalisé" />
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="maintenance.author" class="form-control" placeholder="Responsable" type="text"
                    title="Auteur de l'opération" />
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="maintenance.reason" class="form-control" placeholder="Cause" type="text"
                    title="Cause de la maintenance" />
                </div>
                <div class="input-group input-maintenance-registration">
                  <input v-model="maintenance.price" class="form-control" placeholder="Coût de l'opération" type="number"
                    min="1" step="any" title="Prix de la main d'oeuvre, pièces.." />
                </div>
                <div class="ui icon input input-group input-maintenance-registration">
                  <label v-if="maintenance.invoice != 'null'" class="ui grey below label">
                    {{ maintenance.invoice }}
                  </label>
                  <input type="file" style="display: none" title="Facture" @change="onFileSelected" placeholder="Image"
                    ref="fileInput" />
                  <button class="fluid ui basic labeled icon button" @click="$refs.fileInput.click()">
                    <i class="image icon"></i>
                    Déposer une facture
                  </button>
                </div>
              </div>

              <!-- BUTTONS ACTIONS -->
              <b-button @click="onClickEditMaintenance" block variant="primary" size="sm">Enregistrer les
                modifications</b-button>
              <b-button @click="onClickDeleteMaintenance" block variant="danger" size="sm" id="button-delete">Supprimer
                l'entretien</b-button>
            </div>

            <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
          </b-col>
        </b-row>
      </b-container>
    </div>

  </layout>
</template>

<script>
import api from '../api'
import router from '../router'
import Layout from '../layouts/Layout.vue'

export default {
  name: 'EditMaintenance',
  components: { Layout },

  async beforeCreate() {
    this.maintenance = await api.readMaintenance({
      maintenanceId: this.$route.params.maintenanceid,
      carId: this.$route.params.carid,
    })
  },
  data() {
    return {
      maintenance: {},
      errorMessage: '',
    }
  },
  methods: {
    onFileSelected(event) {
      this.invoice = event.target.files[0]
    },
    async onClickEditMaintenance() {
      // Update invoice first
      let newInvoiceID = null
      if (this.invoice) {
        const { fileName, success, errors, _message } = await api.storeFile(this.invoice)
        if (success) {
          newInvoiceID = fileName
          this.errorMessage = ''
        }
      }

      api
        .editMaintenance({
          description: this.maintenance.description,
          date: this.maintenance.date,
          mileage: this.maintenance.mileage,
          reason: this.maintenance.reason,
          price: this.maintenance.price,
          author: this.maintenance.author,
          invoice: newInvoiceID,
          carId: this.$route.params.carid,
          maintenanceId: this.$route.params.maintenanceid,
        })
        .then((response) => {
          this.successMessage = 'Votre entretien : ' + response.data.description + ' à été édité avec succès !'
          this.$notify({
            type: 'success',
            title: 'Entretien',
            text: 'Votre entretien à bien été édité',
            duration: 5000,
            speed: 1000,
          })
          this.errorMessage = ''
          router.push({ name: 'CarProfile' })
        })
        .catch((e) => {
          this.errorMessage = e.error
          this.successMessage = ''
        })
    },
    onClickDeleteMaintenance() {
      api
        .deleteMaintenance(this.$route.params.carid, this.$route.params.maintenanceid)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Entretien',
            text: 'Votre entretien à bien été supprimé',
            duration: 5000,
            speed: 1000,
          })
          router.push({ name: 'CarProfile' })
        })
        .catch((e) => {
          this.errorMessage = e.error
        })
    },
  },
}
</script>

<style>
/* login */
.main-container {
  margin: 30px 0 100px 0;
}

.register {
  padding: 10px 10% 10px 10%;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 1px;
}

.form-control {
  font-size: 13px;
}

#button-delete {
  margin: 20px 0 20px 0;
}

.input-car-registration {
  margin-bottom: 20px;
}
</style>

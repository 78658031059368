import axios from 'axios'
import auth from './services/auth'
import 'dotenv/config'
import router from './router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API
axios.defaults.headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${auth.retrieveToken()}`,
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.warn(error)
    if (error.response.status === 401 || error.response.status === 498) {
      auth.destroyToken()
      router.push({ name: 'Login' })
    }
    return Promise.reject(error)
  }
)

export default {
  /* Read a maintenance referenced by a id */
  readMaintenance: (credentials) => {
    let p_carId = credentials.carId
    let p_maintenanceId = credentials.maintenanceId

    return new Promise((resolve, reject) => {
      axios
        .get(`/cars/${p_carId} /maintenances/${p_maintenanceId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          if (e.response) {
            reject(e.response.data.error)
          } else {
            reject("Désolé, nous n'arrivons pas à retrouver cette maintenance ☹️")
          }
        })
    })
  },

  /* Read all maintenances referenced by a car id */
  loadMaintenances(carId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`cars/${carId}/maintenances/`)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          if (e.response) {
            reject(e.response.data)
          } else {
            reject({ error: "Désolé, nous n'arrivons pas à charger vos données pour le moment ☹️" })
          }
        })
    })
  },

  /* Request POST to register a new user */
  readCars: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`cars`)
        .then((response) => {
          localStorage.setItem('cars', JSON.stringify(response.data))
          resolve(response)
        })
        .catch((e) => {
          if (e.response) {
            reject(e.response.data.error)
          } else {
            console.log("API Down... Disconnect user")
            reject("Désolé, nous n'arrivons pas à charger vos données pour le moment ☹️")
          }
        })
    })
  },
  login: (credentials) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`users/login`, credentials)
        .then((response) => {
          auth.storeToken(response.data.token)
          axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + response.data.token,
          }
          resolve(response)
        })
        .catch((e) => {
          if (e.response) {
            reject(e.response.data.error)
          } else {
            reject('La plateforme de connexion est momentanément indisponible 😞')
          }
        })
    })
  },
  registrationUser: (credentials) => {
    const data = {
      email: credentials.email,
      password: credentials.password,
      firstname: credentials.firstname,
      lastname: credentials.lastname,
      birthdate: credentials.birthdate,
      phonenumber: credentials.phonenumber,
      gender: credentials.gender,
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`users/register`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          if (e.response) {
            reject(e.response.data)
          } else {
            reject({
              error: "La plateforme de d'inscription est momentanément indisponible 😞",
            })
          }
        })
    })
  },
  sendUrlToResetPassword: (email) => {
    const data = {
      email: email,
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`/users/me/resetpassword/`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  resetPassword: (credentials) => {
    const data = {
      newPassword: credentials.password,
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`/users/me/resetpassword`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  updatePassword: (credentials) => {
    const data = {
      password: credentials.password,
      newpassword: credentials.newPassword,
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`/users/me/password`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  confirmAccount: (authtoken) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/users/confirmation/${authtoken}`)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  readCar: (carId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cars/${carId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  readUser: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/users/me`)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  sendLinkToResetEmail: (credentials) => {
    const data = {
      email: credentials.newEmail,
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`/users/me/email/`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  createCar: (credentials) => {
    const formData = new FormData()

    formData.append('registration', credentials.registration)
    credentials.registrationDate ? formData.append('registrationDate', credentials.registrationDate) : null
    formData.append('brand', credentials.brand)
    formData.append('model', credentials.model)
    credentials.modelVersion ? formData.append('modelVersion', credentials.modelVersion) : null
    formData.append('fuel', credentials.fuel)
    credentials.horses ? formData.append('horses', credentials.horses) : null
    formData.append('carImage', credentials.carImage)

    return new Promise((resolve, reject) => {
      axios
        .post(`/cars/new`, formData, {

          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  deleteCar: (carId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/cars/${carId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  deleteMaintenance: (carId, maintenanceId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/cars/${carId}/maintenances/${maintenanceId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  editCar: (credentials) => {
    const formData = new FormData()

    formData.append('registration', credentials.registration)
    formData.append('brand', credentials.brand)
    formData.append('model', credentials.model)
    formData.append('modelVersion', credentials.modelVersion)
    formData.append('fuel', credentials.fuel)
    formData.append('horses', credentials.horses)
    formData.append('carImage', credentials.carImage)

    return new Promise((resolve, reject) => {
      axios
        .put(`/cars/${credentials.carId}`, formData)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  createMaintenance: (credentials) => {
    const formData = new FormData()

    formData.append('description', credentials.description)
    formData.append('date', credentials.date)
    formData.append('mileage', credentials.mileage)
    formData.append('author', credentials.author)
    formData.append('price', credentials.price)
    formData.append('reason', credentials.reason)
    formData.append('invoice', credentials.invoice)

    return new Promise((resolve, reject) => {
      axios
        .post(`/cars/${credentials.carId}/maintenances/new`, formData, {
          onUploadProgress: (uploadEvent) => {
            console.log('Upload progess: ' + uploadEvent.loaded + '/' + uploadEvent.total)
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  editMaintenance: (credentials) => {
    const formData = new FormData()

    formData.append('description', credentials.description)
    formData.append('date', credentials.date)
    formData.append('mileage', credentials.mileage)
    formData.append('author', credentials.author)
    formData.append('price', credentials.price)
    formData.append('reason', credentials.reason)
    formData.append('invoice', credentials.invoice)

    return new Promise((resolve, reject) => {
      axios
        .put(`/cars/${credentials.carId}/maintenances/${credentials.maintenanceId}`, formData, {
          onUploadProgress: (uploadEvent) => {
            console.log('Upload progess: ' + Math.round(uploadEvent.loaded / uploadEvent.total) * 100 + '%')
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  contact: (credentials) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/contact`, credentials)
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  exportMaintenances: (credentials) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cars/${credentials.carId}/export`, {
          responseType: 'blob',
        })
        .then((response) => {
          resolve(response)
        })
        .catch((e) => {
          reject(handleError(e))
        })
    })
  },
  searchLicensePlate: (credentials) => {
    if (credentials.registration && typeof credentials.registration === 'string') {
      console.log(credentials.registration)
      return new Promise((resolve, reject) => {
        axios
          .get(`/cars/find/${credentials.registration.toString().toUpperCase()}`)
          .then((response) => {
            resolve(response)
          })
          .catch((e) => {
            reject(handleError(e))
          })
      })
    }
  },
  getFile: (fileID) => {
    const baseURL = process.env.VUE_APP_BASE_URL_BOX
    const projectCode = process.env.VUE_APP_PROJECT_CODE_BOX
    const apiKey = process.env.VUE_APP_BOX_API_KEY
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/api/Storage?p_ProjectCode=${projectCode}&p_FileName=${fileID}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'X-API-KEY': apiKey,
        },
        responseType: 'blob',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.blob()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  storeFile: (file) => {
    const baseURL = process.env.VUE_APP_BASE_URL_BOX
    const projectCode = process.env.VUE_APP_PROJECT_CODE_BOX
    const apiKey = process.env.VUE_APP_BOX_API_KEY

    const formData = new FormData()
    formData.append('Project', projectCode)
    formData.append('File', file)

    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/api/Storage`, {
        method: 'POST',
        headers: {
          "Access-Control-Allow-Origin": "*",
          'X-API-KEY': apiKey,
        },
        body: formData,
        onUploadProgress: (uploadEvent) => {
          console.log('Upload progess: ' + uploadEvent.loaded + '/' + uploadEvent.total)
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
}

const handleError = (error) => {
  if (error.response) {
    return error.response.data
  } else {
    return {
      error: 'Le serveur distant est momentanément indisponible 😞',
    }
  }
}

<template>
  <div id="main-container">
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col md="auto" id="cp-container">
          <h1 id="title-container">Me contacter</h1>
          <p id="under-title">
            Pour toute question ou demande d'ordre commercial contactez-moi en remplissant ce formulaire, je vous
            répondrais dans les plus brefs délais.
          </p>
          <form action="javascript:void(0);" accept-charset="utf-8">
            <div class="modal-body" style="padding: 5px">
              <div class="modal-body" style="padding: 5px">
                <transition name="bounce">
                  <div>
                    <div class="ui error message mini" v-if="errorMessage">
                      <div>Une erreur est survenue</div>
                      <p>{{ errorMessage }}</p>
                    </div>
                    <div class="ui success message mini" v-if="successMessage">
                      <div>Succès</div>
                      <p>{{ successMessage }}</p>
                    </div>
                  </div>
                </transition>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6" style="padding-bottom: 10px">
                    <input class="form-control" name="firstname" v-model="firstname" placeholder="Prénom" type="text"
                      required autofocus />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6" style="padding-bottom: 10px">
                    <input class="form-control" name="lastname" v-model="lastname" placeholder="Nom" type="text"
                      required />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12" style="padding-bottom: 10px">
                    <input class="form-control" name="email" v-model="email.value" placeholder="E-mail" type="text"
                      required />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12" style="padding-bottom: 10px">
                    <input class="form-control" v-model="email.subject" name="subject" placeholder="Sujet" type="text"
                      required />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <textarea style="resize: vertical" :minlength="message.minlength" :maxlength="message.maxlength"
                      v-model="message.text" class="form-control" placeholder="Message..." rows="6" name="comment"
                      required></textarea>
                    <span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer" style="margin-bottom: -14px">
              <button @click="sendEmail" class="btn btn-primary" value="Envoyer">Envoyer</button>
              <button @click="reset" class="btn btn-danger" value="Effacer">Effacer</button>
            </div>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from '../api'
import { emailRegExp } from '../utils/regex.utils'

export default {
  name: 'Contact',
  data: function () {
    return {
      firstname: '',
      lastname: '',
      email: {
        subject: '',
        value: '',
        valid: true,
      },
      features: ['Reactivity', 'Encapsulation', 'Data Binding'],
      selection: {
        member: '0',
        framework: 'vue',
        features: [],
      },
      message: {
        text: '',
        maxlength: 455,
        minlength: 10,
      },
      errorMessage: '',
      successMessage: '',
    }
  },
  methods: {
    reset: function () {
      this.message.text = ''
    },
    // validate by type and value
    validate: function (type, value) {
      if (type === 'email') {
        this.email.valid = this.isEmail(value) ? true : false
      }
    },
    // check for valid email adress
    isEmail: function (value) {
      return emailRegExp.test(value)
    },
    // check or uncheck all
    checkAll: function (event) {
      this.selection.features = event.target.checked ? this.features : []
    },
    sendEmail() {
      if (
        this.firstname &&
        this.lastname &&
        this.email.subject &&
        this.email.valid &&
        this.message.text.length >= this.message.minlength &&
        this.message.text.length <= this.message.maxlength
      ) {
        const templateParams = {
          from_firstname: this.firstname,
          from_lastname: this.lastname,
          from_email: this.email.value,
          subject: this.email.subject,
          message: this.message.text,
        }
        api
          .contact(templateParams)
          .then((response) => {
            this.successMessage = response.data.message
            this.errorMessage = ''
            this.reset()
          })
          .catch((error) => {
            this.successMessage = ''
            this.errorMessage = error.error
          })
      }
    },
  },
  watch: {
    // watching nested property
    'email.value': function (value) {
      this.validate('email', value)
    },
  },
}
</script>

<style>
/* responsive */
@media only screen and (max-width: 991px) {

  #logo,
  #column-logo {
    background-image: none;
    display: none;
  }
}

/* login */
#main-container {
  margin: 50px 0 8% 0;
}

#cp-container {
  padding: 30px;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 5px;
}

.modal-body:not(.two-col) {
  padding: 0px;
}

.glyphicon {
  margin-right: 5px;
}

.modal-body .radio,
.modal-body .checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}

.modal-body .list-group {
  margin-bottom: 0;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.modal-body .radio label,
.modal-body .checkbox label {
  display: block;
}

.modal-footer {
  margin-top: 0px;
}

@media screen and (max-width: 325px) {
  .btn-close {
    margin-top: 5px;
    width: 100%;
  }

  .btn-results {
    margin-top: 5px;
    width: 100%;
  }

  .btn-vote {
    margin-top: 5px;
    width: 100%;
  }
}

.modal-footer .btn+.btn {
  margin-left: 0px;
}

.btn-danger {
  margin-left: 20px;
}
</style>

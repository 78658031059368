const brandsWithModels = {
    'Audi': ['A3', 'A4', 'A6', 'Q5', 'Q7', 'A1', 'A5', 'A8', 'Q3', 'Q8', 'S3', 'S4', 'S6', 'S8', 'RS3', 'RS4', 'RS6', '80', '100'],
    'BMW': ['3 Series', '5 Series', 'X3', 'X5', '1 Series', '7 Series', 'X1', 'X6', 'M2', 'M3', 'M4', 'M5', 'M8', 'Z4', '2002', '3.0 CSL'],
    'Mercedes-Benz': ['C-Class', 'E-Class', 'GLC', 'GLE', 'A-Class', 'S-Class', 'CLA', 'GLA', 'GLB', 'GLS', 'SLC', 'SL', 'AMG GT', '190E', '300SL'],
    'Volkswagen': ['Golf', 'Passat', 'Tiguan', 'Polo', 'Arteon', 'Atlas', 'Up', 'Touareg', 'T-Roc', 'ID.3', 'ID.4', 'Golf R', 'Golf GTI', 'Beetle', 'Karmann Ghia'],
    'Volvo': ['S60', 'S90', 'XC40', 'XC60', 'XC90', 'V60', 'V90', 'C30', 'S40', 'V40', 'C70', 'XC70', 'PV544', 'Amazon', 'P1800'],
    'Peugeot': ['208', '308', '3008', '5008', '508', '2008', '3008', '108', '208 GTi', '308 GTi', 'RCZ', '206', '207', '306', '406'],
    'Renault': ['Clio', 'Megane', 'Captur', 'Kadjar', 'Scenic', 'Koleos', 'Twingo', 'Zoe', 'Mégane RS', 'Clio RS', 'Talisman', 'Fluence', '5', '4', '8', '16'],
    'SEAT': ['Ibiza', 'Leon', 'Ateca', 'Tarraco', 'Alhambra', 'Arona', 'Mii', 'Leon Cupra', 'Ibiza Cupra', 'Ateca Cupra', 'Toledo', 'Exeo'],
    'Škoda': ['Octavia', 'Superb', 'Kodiaq', 'Karoq', 'Scala', 'Fabia', 'Yeti', 'Citigo', 'Octavia RS', 'Superb SportLine', 'Kodiaq RS', 'Felicia', 'Roomster', 'Rapid']
  }

export { brandsWithModels }
<template>
    <div id="sidebar">
        <AppHeader />
        <sidebar-menu :menu="menu" id="sidebar-menu" width="250px">
            <template v-slot:header>
                <div class="header">
                    <h1>Mon Suivi Auto</h1>

                    <router-link to='/'><img :src="`${publicPath}/logo.png`" alt="logo" class="logo" /></router-link>
                    <hr class="my-2 black" />
                    <div @click="disconnect" class="disconnect">Déconnexion <span
                            class="fa fa-arrow-right-from-bracket"></span></div>
                </div>
            </template>

        </sidebar-menu>

        <main id="content">
            <slot></slot> <!-- This is the slot where the content of the page will be injected -->
        </main>
        <footer>
            <AppFooter />
        </footer>
    </div>
</template>
  
<script>
import AppHeader from '../components/AppHeader.vue'
import AppFooter from '../components/AppFooter.vue'
import auth from '../services/auth'

export default {
    name: 'Layout',
    components: {
        AppHeader,
        AppFooter
    },
    data: () => {
        return {
            publicPath: window.location.origin,
            menu: [
                {
                    href: '/cars',
                    title: 'Garage',
                    icon: 'fa fa-warehouse',
                    class: 'menu-item'
                },
                {
                    href: '/user/me',
                    title: 'Profile',
                    icon: 'fa fa-user',
                    class: 'menu-item'
                }
            ],
        }
    },
    methods: {
        disconnect() {
            auth.destroyToken()
            this.$router.push('/login')
        }
    }
}
</script>
  
<style>
hr {
    color: black;
    height: 1px;
}

#sidebar-menu {
    height: 100%;
    width: 100%;
}

#content,
footer {
    margin-left: 250px
}

@media screen and (max-width: 768px) {
    #sidebar-menu {
        width: 50px;
    }

    #content,
    footer {
        margin-left: 50px
    }
}

.header {
    background-color: rgb(90, 90, 90);
    color: white;
    min-height: 200px;
    padding: 40px 10px 10px 10px;
}

@media screen and (max-width: 768px) {

    .header,
    .disconnect {
        display: none
    }
}

.header>h1 {
    font-size: 22px;
    text-align: center;
}

.header>.disconnect {
    text-align: center;
    color: var(--secondary-text-color);
    display: block;
    cursor: pointer;
}

.header>.disconnect:hover {
    text-decoration: underline;
}

img.logo {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: block;
}

.title-menu {
    font-weight: 400 !important;
}

.vsm--title {
    font-weight: 400 !important;
}
</style>
  
<template>
  <Layout>
    <div id="cars">
      <main-bar>
        <h2>Garage</h2>
      </main-bar>
      <div v-if="!loaded">
        <div class="ui">
          <div class="ui active dimmer">
            <div class="ui indeterminate text loader">Chargement des données</div>
          </div>
          <p></p>
        </div>
      </div>

      <transition name="bounce" appear v-else-if="loaded && !errorMessage">
        <div class="cards row row-cols-1 row-cols-md-3 g-4">
          <div class="card" @click="readCar(car.id)" v-for="car in cars" :key="car.id">
            <img class="card-img-top" :src="car.pictureURL" alt="Card image cap">
            <hr class="separator-img" />
            <div class="card-body">
              <h5 class="card-title  car-card-registration">{{ car.registration }}</h5>
              <p class=" card-text">{{ car.brand }}</p>
              <p class="card-text"> {{ car.model }} {{ car.modelVersion }}</p>
              <p class="card-text text-muted"> {{ car.fuel }}</p>
            </div>
          </div>
          <div class="card">
            <div class="card-body vertical-center">
              <router-link to="/cars/new">
                <span class="fa fa-plus add-vehicle" aria-hidden="true"></span>
                <button class="btn w-100">Ajouter un véhicule</button>
              </router-link>
            </div>
          </div>
        </div>
      </transition>
      <transition name="bounce" appear v-else-if="errorMessage && loaded">
        <div class="ui error message">
          <div>Une erreur est survenue</div>
          <p>{{ errorMessage }}</p>
        </div>
      </transition>
    </div>
  </Layout>
</template>

<script>
import router from '../router'
import api from '../api'
import Layout from '../layouts/Layout.vue'
import MainBar from '../components/MainBar.vue'

export default {
  components: { Layout, MainBar },
  name: 'Garage',
  data: () => {
    return {
      errorMessage: '',
      loaded: false,
      cars: null,
    }
  },
  methods: {
    /* Click event when a user select a car */
    readCar(carId) {
      api
        .readCar(carId)
        .then((response) => {
          const carSelected = this.cars.find((car) => car.id === carId)
          localStorage.setItem('car_selected', JSON.stringify({ ...response.data, pictureURL: carSelected.pictureBlobURL }))
          router.push(`cars/${carId}`)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  beforeCreate() {
    api
      .readCars()
      .then(async (response) => {
        const reader = new FileReader();

        this.errorMessage = ''
        this.cars = response.data

        let api_down = false

        for (const car of this.cars) {
          if (car.picture == "default_car.png" || api_down) {
            car.pictureURL = `/${car.picture}`
            continue
          }

          try {
            const blob = await api.getFile(car.picture)
            const pictureURL = URL.createObjectURL(blob)

            car.pictureURL = pictureURL
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              car.pictureBlobURL = reader.result
            }
          } catch (e) {
            console.log(e.name)
            if (e.name == 'NetworkError' || e.name == 'TypeError')
              api_down = true
          }
        }
      })
      .catch((err) => {
        this.errorMessage = err.error
      })
      .finally(() => {
        this.loaded = true
      })
  },

}
</script>

<style>
/* CARDS */

.cards {
  margin: 30px !important;
  gap: 20px !important;
}

.card {
  max-width: 220px !important;
  min-height: 240px !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}


.car-card-registration {
  width: 100%;
  font-weight: 600;
  letter-spacing: 1.25px;
}

.separator-img {
  color: var(--grey);
  margin: 15px;
  margin: 0;
}

@media (max-width: 640px) {
  .card-text .car-description .car-registration .car-title {
    display: flex;
    float: left;
  }
}

.card-img-top {
  align-self: center;
  max-width: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 10px 0 10px 0;
  filter: drop-shadow(0px 0px 1px #272727);
}

.card-title {
  text-align: center;
}

.card-text {
  margin: 0;
  margin-bottom: 5px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: -moz-available;

}

.vertical-center>* {
  width: 100%;

}

.add-vehicle {
  display: block;
  text-align: center;
  font-size: 50px;
}
</style>

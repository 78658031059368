<template>
  <layout>
    <div class="main-container">
      <b-container fluid>
        <b-row align-h="center">
          <b-col md="auto" class="login-container">
            <div class="register">
              <h1 id="title-container">Ajouter un véhicule</h1>
              <p id="under-title">Ajouter votre véhicule en renseignant les champs requis ci-dessous</p>

              <transition mode="out-in" name="bounce">
                <div>
                  <div key="success" class="ui success message mini" v-if="successMessage">
                    <div>Opération réalisée avec succès</div>
                    <p>{{ successMessage }}</p>
                  </div>
                  <div key="error" class="ui error message mini" v-if="errorMessage">
                    <div>Une erreur est survenue</div>
                    <p>{{ errorMessage }}</p>
                  </div>
                </div>
              </transition>

              <!-- INPUT -->
              <div class="form-group">
                <div class="ui icon input input-group input-car-registration">
                  <label v-if="picture" class="ui grey below label">{{ picture.name }} </label>
                  <input type="file" style="display: none" title="Image du véhicule" @change="onFileSelected"
                    placeholder="Image" ref="fileInput" />
                  <button class="fluid ui basic labeled icon button" @click="$refs.fileInput.click()">
                    <i class="image icon"></i>
                    Déposer une image
                  </button>
                </div>

                <div class="ui icon input input-group input-car-registration">
                  <input v-model="registration" autofocus placeholder="AA-123-BB" type="text" pattern="^.{1,10}$"
                    title="Immatriculation" :disabled="plateLoading" />
                  <i class="inverted circular search link icon d-none" @click="searchLicensePlate()"
                    :disabled="plateLoading"></i>
                  <span class="required-field">*</span>
                </div>

                <div class="input-group input-car-registration">
                  <input v-model="registrationDate" class="form-control" autofocus placeholder="Date d'immatriculation"
                    title="Date d'immatriculation" type="date" pattern="^.{1,10}$" />
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="brand" class="form-control" placeholder="Marque" type="text" pattern="^.{5,100}$"
                    title="Marque du véhicule (exemple: Renault)" :disabled="plateLoading" list="datalistBrands" />
                  <datalist id="datalistBrands">
                    <option v-for="b in Object.keys(brands)" :key="b" :value="b"></option>
                  </datalist>
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="model" class="form-control" placeholder="Modèle" type="text" list="datalistModels"
                    title="Modèle (exemple: Clio 4)" :disabled="plateLoading" />
                  <datalist id="datalistModels">
                    <option v-for="m in brands[brand]" :key="m" :value="m"></option>
                  </datalist>
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="modelVersion" class="form-control" placeholder="Version du modèle" pattern="^.{5,100}$"
                    type="text" title="Version du modèle (exemple: 1.5 dCi)" :disabled="plateLoading" />
                </div>
                <div class="input-group input-car-registration">
                  <select class="form-select form-control" aria-label="Motorisation" title="Motorisation" v-model="fuel">
                    <option selected value="" disabled>-</option>
                    <option v-for="f in fuels" :key="f" :value="f">{{ f }}</option>
                  </select>
                  <span class="required-field">*</span>
                </div>
                <div class="input-group input-car-registration">
                  <input v-model="horses" class="form-control" title="Puissance en chevaux (exemple 90)"
                    placeholder="Puissance" type="number" min="5" pattern="^.{5,100}$" :disabled="plateLoading" />
                </div>
              </div>

              <!-- BUTTONS ACTIONS -->
              <b-button :disabled="loading" @click="onClickAddVehicle" block variant="primary" size="sm"
                id="button-register">Ajouter le
                véhicule</b-button>
            </div>

            <a id="link-go-back" @click="$router.back()">Revenir en arrière</a>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </layout>
</template>

<script>
import api from '../api'
import Layout from '../layouts/Layout.vue'
import { brandsWithModels } from '../utils/const.utils'

export default {
  name: 'AddCar',
  components: { Layout },

  data() {
    return {
      loading: false,
      plateLoading: false,
      registration: '',
      registrationDate: '',
      brand: '',
      model: '',
      modelVersion: null,
      fuel: '',
      horses: null,
      picture: null,
      errorMessage: '',
      successMessage: '',
      fuels: ['Diesel', 'Essence', 'Electrique', 'GPL', 'Hybride', 'Hybride rechargeable', 'Ethanol'],
      brands: brandsWithModels
    }
  },
  methods: {
    onFileSelected(event) {
      this.picture = event.target.files[0]
    },
    async onClickAddVehicle() {
      if (this.registration && this.brand && this.model && this.fuel) {
        this.loading = true

        // Update picture first
        let newPictureId = null
        if (this.picture) {
          const { fileName, success, errors, _message } = await api.storeFile(this.picture)
          if (success) {
            newPictureId = fileName
            this.errorMessage = ''
          }
        }

        if (!newPictureId) {
          newPictureId = 'default_car.png'
        }

        api
          .createCar({
            registration: this.registration,
            registrationDate: this.registrationDate,
            brand: this.brand,
            model: this.model,
            modelVersion: this.modelVersion,
            fuel: this.fuel,
            horses: this.horses,
            carImage: newPictureId,
          })
          .then((response) => {
            this.successMessage =
              "Votre véhicule à l'immatriculation " + response.data.registration + ' à été créée avec succès !'
            this.errorMessage = ''
          })
          .catch((e) => {
            this.errorMessage = e.error
            this.successMessage = ''
          })
          .finally(() => {
            this.loading = false
          })

      } else {
        this.errorMessage = "Les champs suivis d'un astérisque sont obligatoires"
      }
    },
    searchLicensePlate() {
      if (this.registration) {
        this.plateLoading = true
        api
          .searchLicensePlate({
            registration: this.registration,
          })
          .then((response) => {
            if (response && response.data) {
              this.horses = response.data.horses ? response.data.horses : this.horses
              this.brand = response.data.brand ? response.data.brand : this.brand
              this.model = response.data.model ? response.data.model : this.model
              this.modelVersion = response.data.modelVersion ? response.data.modelVersion : this.modelVersion

              if (response.data.modelVersion)
                this.fuel = this.diesels.some((v) => response.data.modelVersion.toLowerCase().contains(v.toLowerCase()))
                  ? 'Diesel'
                  : 'Essence'
            }
            this.errorMessage = ''
          })
          .catch((e) => {
            this.errorMessage = e.error
            this.successMessage = ''
          })
          .finally(() => {
            this.plateLoading = false
          })
      }
    },
  },
}
</script>

<style>
/* login */
.main-container {
  margin: 30px 0 100px 0;
}

.register {
  padding: 10px 10% 10px 10%;
  background-color: white;
  box-shadow: 0 0px 3px 1px rgba(143, 143, 143, 0.2);
  border-radius: 1px;
}

.form-control {
  font-size: 13px;
}

#button-register {
  margin: 20px 0 20px 0;
}

.input-car-registration {
  margin-bottom: 20px;
}
</style>

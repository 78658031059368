import "dotenv/config";

const TOKEN_NAME = `${process.env.NODE_ENV}_monsuiviauto_user_token`
const USER_NAME = `${process.env.NODE_ENV}_monsuiviauto_user`

export default {
  storeToken(user_token) {
    localStorage.setItem(`${TOKEN_NAME}`, user_token);
  },
  isAuthentificated() {
    const token = this.retrieveToken();
    return token != null && token != undefined && token != '';
  },
  destroyToken() {
    localStorage.clear();
  },
  retrieveToken() {
    return localStorage.getItem(`${TOKEN_NAME}`);
  },

  /**
   * Store User
   * @param {
   * } user 
   */
  storeUser(user) {
    localStorage.setItem(USER_NAME, JSON.stringify(user));
  },
  /**
   * 
   * @returns get User
   */
  getUser() {
    return JSON.parse(localStorage.getItem(USER_NAME));
  }
};
